.Rectangle-123 {
  display: grid;
  grid-template-columns: 0.5vw 3vw 0.1vw 30vw 50vw;
  grid-gap: 20px;
  grid-template-areas: '. kk . ss  . .';
  width: 100%;
  height: 10vh;
  max-height: 5vh;
  /* margin: 0 0 18%; */
  padding: 2vh 75vw 0 14.5vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 1;
  align-items: top;
}

.Korean {
  width: 5vw;
  height: 2vh;
  font-family: sans-serif;
  font-size: 2vh;
  font-weight: bold;
  color: rgb(255, 255, 255);
  grid-area: kk;
  top: 0;
}
.SEP {
  width: 10vw;
  height: 4vh;
  font-family: sans-serif;
  font-size: 3vh;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
  grid-area: ss;
}

/* wrapper content css */
#wrapper {
  display: grid;
  z-index: 1;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  margin-top: 70px;
  margin-bottom: 6.4vh;
  margin-left: 14.5vw;
  margin-right: 14.5vw;
  grid-gap: 20px;
  max-height: 100%;
  grid-template-areas:
    '. . . .'
    '. t t t'
    '. . a a'
    '. . a a'
    '. . . .';
  z-index: 1;
}

.image1 {
  z-index: 0;
}
.common-img {
  position: fixed;
  background-size: 100%;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-repeat: no-repeat;
}

.img1 {
  background-image: url('./image/untitled.64.png');
}

.img2 {
  position: absolute; /* Only this differs */
  background-image: url('./image/untitled.63.png');
}

.img3 {
  background-image: url('./image/untitled.65.png');
}

.img4 {
  background-image: url('./image/untitled.62.png');
}

.box1 {
  grid-area: t;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.Adaptive-Front-lighting,
.using,
.nano-scaled-meta-surface {
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-align: right;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* 'right'보다 더 적절한 이름 */
  /* flex: 1 1 auto; */
}

.Adaptive-Front-lighting,
.using {
  font-size: 1.5vw;
  height: 25%;
}

.nano-scaled-meta-surface {
  font-family: sans-serif;
  font-size: 3vw;
  height: 50%;
  white-space: nowrap;
}

.box2 {
  grid-area: a;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.SEPs-Adaptive-Front-lighting-using-nano-scaled-meta-surface-is-more-compact-than-previous-models {
  font-family: sans-serif;
  font-size: 1vw;
  font-weight: normal;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: left;
  word-wrap: break-word;
  margin-bottom: 0;
}

.mode1,
.mode2 {
  font-family: sans-serif;
  font-size: 1vw;
  font-weight: lighter;
  color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-top: 0; /* 여백 제거 */
}

.mo1,
.mo3 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Ellipse-8,
.Ellipse-9,
.Ellipse-10,
.Ellipse-11 {
  width: 1vh;
  height: 2vh;
  border: solid 1px rgb(132, 168, 194);
  margin-right: 5px;
}

.Low-Beam-module,
.High-Beam-module,
.Driving-Assistance,
.Steering-Assistance {
  height: 2vh;
  margin-left: 5px;
  /* font-size: 0.7vw; */
  font-size: 10px;
  color: rgb(255, 255, 255);
  white-space: nowrap;
}

/* wrapper1 content Css */

#wrapper1 {
  display: grid;
  z-index: 1;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 5fr 5fr 5fr 5fr 5fr;
  margin-top: 0;
  margin-bottom: 6.4vh;
  margin-left: 14.5vw;
  margin-right: 14.5vw;
  grid-gap: 5vh;

  grid-template-areas:
    'm m m m .'
    'v1 v1 t1 t1 t1'
    't2 t2 t2 v2 v2'
    'v3 v3 t3 t3 t3'
    't4 t4 t4 v4 v4';
  z-index: 1;
}

.common-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: top;
  height: 100%;
}

.box3 {
  grid-area: m;
  font-family: sans-serif;
  font-weight: bold;
}

.mo {
  font-family: sans-serif;
  font-size: 3vw;
  flex: 1 1 100%;
  white-space: nowrap;
  color: white;
}

.box4 {
  grid-area: v1;
}

.box5 {
  grid-area: v2;
}

.box6 {
  grid-area: v3;
}

.box7 {
  grid-area: v4;
}

.box8 {
  grid-area: t1;
}

.box9 {
  grid-area: t2;
}

.box10 {
  grid-area: t3;
}

.box11 {
  grid-area: t4;
}

.common-title {
  font-size: 2vw;
  margin-top: 7vh;
  font-weight: bold;
  font-family: sans-serif;
  color: white;
}

.common-text {
  font-size: 1vw;
  word-wrap: break-word;
  color: white;
  font-family: sans-serif;
  font-weight: normal;
}

.L {
  text-align: right;
}

.L-t {
  text-align: right;
}

.S1-t {
  text-align: left;
}

.D-t {
  text-align: right;
}

.D {
  text-align: right;
}

/* third-page wrapper 2 */
#wrapper2 {
  display: grid;
  z-index: 1;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 3fr 3fr 3fr 3fr 1fr;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 14.5vw;
  margin-right: 14.5vw;
  grid-gap: 20px;
  max-height: 100vh;
  grid-template-areas:
    '. . . . .'
    'd d d d d'
    'i3 i3 i1 i1 i1'
    'i3 i3 i1 i1 i1'
    '. . . . . ';
}

.detail {
  grid-area: d;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 3vw;
  height: 100%;
  flex: 1 1 100%;
  white-space: nowrap;
  color: white;
}

.box12 {
  grid-area: i1;
  position: relative;
  display: flex;
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  width: 100%; /* Ensure it takes up full width */
  height: auto; /* Maintain aspect ratio */
  overflow: hidden; /* Hide any overflow */
  filter: drop-shadow(5px 5px 5px 5px #ffffff) opacity(50%);
}
.box12 img {
  width: 100%; /* Scale image to fit container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure image covers the container while maintaining aspect ratio */
}

.C-t {
  font-weight: normal;
}

.C-t2 {
  font-weight: lighter;
}

.box14 {
  grid-area: i3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

/* fourth-page wrapper3 */
#wrapper3 {
  display: grid;
  z-index: 1;
  grid-template-columns: 0.2fr repeat(3, 1fr) 0.2fr;
  grid-template-rows: 50px 1fr 3fr 3fr 5px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 14.5vw;
  margin-right: 14.5vw;
  grid-gap: 20px;
  min-height: 100vh;
  grid-template-areas:
    '. . . . .'
    's s s s s'
    'sv sv sv sv sv'
    'sv sv sv sv sv'
    '. . . . .';
}
.box15 {
  grid-area: s;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* 아래쪽 정렬을 위해 추가 */
  height: 100%;
}
.box16 {
  grid-area: sv;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: top; /* 아래쪽 정렬을 위해 추가 */
  height: 100%;
}
.S {
  word-wrap: break-word;
  text-align: center;
}
.S-t {
  text-align: center;
  font-weight: normal;
}

/* fifth-page wrapper4 */
#wrapper4 {
  display: grid;
  z-index: 1;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 50px 1fr 3fr 3fr 5px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 14.5vw;
  margin-right: 14.5vw;
  grid-gap: 20px;
  min-height: 100vh;
  grid-template-areas:
    '. . . . .'
    'cn cn cn cn cn'
    'cni cni cni cni cni'
    'cni cni cni cni cni'
    '. . . . .';
}
.box17 {
  grid-area: cn;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* 아래쪽 정렬을 위해 추가 */
  height: 100%;
}
.box18 {
  grid-area: cni;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: top; /* 아래쪽 정렬을 위해 추가 */
  height: 100%;
  width: 100%;
}
.box18 img {
  width: 100%; /* Scale image to fit container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure image covers the container while maintaining aspect ratio */
}

/* sixth-page wrapper5 */
#wrapper5 {
  display: grid;
  z-index: 1;
  grid-template-columns: repeat(4, 1fr) 1.5fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 50px;
  margin-top: 0;
  margin-bottom: 6.4vh;
  margin-left: 14.5vw;
  margin-right: 14.5vw;
  grid-gap: 5vh;
  min-height: 100vh;
  grid-template-areas:
    'te te te te te'
    '. nst nst ns ns'
    'vc vc vct vct .'
    '. lmt lmt lm lm'
    '. . . . . ';
}

.wrap5box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 아래쪽 정렬을 위해 추가 */
  height: 100%;
}
.tech {
  justify-content: flex-end; /* 아래쪽 정렬을 위해 추가 */
  grid-area: te;
  font-size: 3vw;
  word-wrap: break-word;
  color: white;
  text-align: left;
  justify-content: center;
  font-weight: bold;
  font-family: sans-serif;
}
.box19 {
  grid-area: ns;
}

.box20 {
  grid-area: vc;
}

.box21 {
  grid-area: lm;
}

.box22 {
  grid-area: nst;
}

.box23 {
  grid-area: vct;
}

.box24 {
  grid-area: lmt;
}

/* seventh-page wrapper6 */
#wrapper6 {
  display: grid;
  z-index: 1;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr 3fr 3fr 1fr;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 14.5vw;
  margin-right: 14.5vw;
  grid-gap: 20px;
  min-height: 100vh;
  grid-template-areas:
    '. . . . .'
    'p1 p1 p1 p1 p1'
    'p2 p2 p2 p2 p2'
    'p2 p2 p2 p2 p2'
    '. . . . . ';
  justify-content: flex-end;
}
.pv {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 3vw;
  grid-area: p1;
  word-wrap: break-word;
  color: rgb(121, 121, 121);
  text-align: left;
  justify-content: flex-end;
}
.pvv {
  grid-area: p2;
  word-wrap: break-word;
  color: white;
}

.root {
  display: grid;
  z-index: 1;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 3vh 5vh 5vh 4vh 3vh;
  margin: 0;
  padding: 0;
  grid-gap: 0.5vw;

  grid-template-areas:
    '. . . . . . .'
    '. l tt tt tt tt .'
    '. l tt tt tt tt .'
    'tt2 tt2 tt2 tt2 tt2 tt2 tt2'
    '. . . . . . .';
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  height: 20vh;
}
.logo {
  grid-area: l;

  display: flex;

  justify-content: right; /* 아래쪽 정렬을 위해 추가 */
  object-fit: cover;
  align-items: right;
  height: 5vw;
}
.tt {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 0.7vw;
  grid-area: tt;
  word-wrap: break-word;
  color: rgb(255, 255, 255);
  text-align: left;
  justify-content: center;
  vertical-align: center;
}
.tt2 {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 0.6vw;
  grid-area: tt2;
  word-wrap: break-word;
  color: rgb(121, 121, 121);
  text-align: center;
  justify-content: flex-end;
}

/* animation */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut1 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes fadeIn1 {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.fade-out,
.fade-in {
  will-change: opacity;
}

.fade-out {
  animation: fadeOut 0.3s ease forwards;
}

.fade-in {
  display: grid; /* fade-in 시 나타나도록 설정 */
  animation: fadeIn 0.8s ease forwards;
}
.fade-out1 {
  animation: fadeOut1 1.2s ease forwards;
}

.fade-in1 {
  animation: fadeIn1 1.2s ease forwards;
}
